.bigBlock {
   
    margin-right:3%;
    width: 40%;
}
.image {
    
    margin-right:3%;
    width:40%;
}
.wykres {
    

margin-right: 5%;


}
.chart {
    float:left;
    width:90%;
    margin-left:10%;
}
.chart p{
    font-size:1.4em;
    border-bottom:2px solid #17A096
}

.chart img{
    margin-top:-70px;
    margin-bottom:50px;
    position:relative;
    display:block;
}

.wynik {
    clear:both;
}
.step2Container {
    padding-left:50px;
    padding-right:50px;
}
.step2Container  .formBlocks {
    display: flex;
    flex-wrap: wrap;
}
.image {
    position:relative;
   
}

.image img {
    position:absolute;

}
.cena{
    position: absolute;
    bottom:10%;
    left:0;
    z-index:9999;
    right:0;
    
}
.cenacc {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items:flex-end;
    width:100%;
}

.cenac{
   align-self: flex-end;
   background-color:#17A096;
   border-radius:40px;
   padding:10px 40px;
   padding-left:50px;
   text-align:center;
   color:#fff;
   font-weight:bold;
   font-size:0.8em;
   margin-left:-20px;
   min-width:100px;
}
.cenac p{
    padding:0;
    margin:0;
    font-size:2em;
}
.ikona{
    width:87px;
    height:97px;
}

.row2 {

    width:100%;
    display:flex;
    margin-bottom:50px;
    
}
.tabela {
    /*display:flex;*/
    width:100%;
    /*margin-top:100px;*/
}
.tabela div {
    width:100%;
}
table {
    border-collapse: collapse;
    /*font-size:0.8em;*/
    width:100%
}
table th {
    border:1px solid  #C4C4C4;
    padding:5px 20px;
    
}
table td {
    border:1px solid  #C4C4C4;
    padding:5px 15px;
    vertical-align: middle;
    
}
.wyr {
    /*font-size:2.7em;*/
    font-size:2em;
    font-weight:bold;
    color:#17A096
}
table tr td:first-child {
    font-weight: bold;
}
table table td {
    border:none;
}
table table tr td:first-child{
    width:80px;
}
table table tr td:nth-child(2){
    width:250px;
}
.infoUnder{
    display:flex;
    justify-content:space-between;
    align-items: center;
}
.infoUt {
    width: 85%;
    float:left;

}
.pdfLink {
    float:right;
}
.pdfLink a{
    display:inline-block;
    background-color:#17A096;
    color:#fff;
    text-align:center;
    text-transform: uppercase;
    font-weight:bold;
    font-size:0.7em;
    padding:10px 40px;
    border-radius:40px;
    text-decoration:none;

}
.savinfo {
    padding-top:30px;
    padding-bottom:30px;
    font-weight:bold;
    
}
.legend {
    border-top:1px solid #17A096;
    font-size:0.8em;
    padding-top:10px;
    padding-bottom:50px;
} 

.gr {
    color: #17A096;
    display:inline-block;
    text-align:right;
    padding-right:10px;
}
.css-bg1rzq-control, .css-1szy77t-control
{
    border:0px solid !important;
    border-radius:0 !important
}
.mob {
    display:none;
}
.nomob {
    display:block;
}

.image > img.fake{

    display:none;
}
@media (max-width:1805px) and (min-width:951px){
    .row2{
        flex-wrap: wrap;
    }
    .wykres {
        width:100%;
       
    }
    .chart {
        width:100%;
        margin:0;
        margin-top:20px;
    }
    .c1{
        width:48%;
        float:left;
        margin:1%;
    }
}
@media (max-width: 950px) {
    .nomob {
        display:none;
    }
    .mob {
        display:block;
    }
    .rowBox{

        margin-top:0;
    }
    .savinfo {
        display:none;
    }
    .row2 {
        flex-wrap: wrap;
    }
    .bigBlock{
        width:100%;
        margin-right:0;
        
    }
    .FormClickBlock{
        width:44%;
    }
    .fullw{
        width:94%;

    }
  
    .image{
        width:100%;
       position: relative;
      
    }
    .image > img.fake{
        width:100%;
        height:auto;
        visibility: hidden;
        position: relative;
        display:block;
    }
    .image > img{
        width:100%;
        top:0;
        left:0;
    }

    .wykres {
        width:100%;
        margin-right:0;
        margin-top:20px
    }
    .chart{
        width:100%;
        margin-left:0;
    }
    .mobBlock{
        width:100%;
    
    box-sizing: border-box;
    margin-top:30px;
    background-color:#f8f8f8;
    border:1px solid #e3e3e3;
   
    padding:20px 40px;
    
    }
    .ktitle {
        font-weight:bold;
        text-align:center;
        font-size:1.05em;
        
    }
    .kcontent {
        font-size:2.5em;
    font-weight:bold;
    color:#17A096;
    text-align:center;
    margin-top:0;
    padding-bottom:10px;
    border-bottom:1px solid gray;
    }
    .kinfo {
        color:gray;
        text-align:center;
    }
    .infoUnder{
        flex-wrap: wrap;
    }
    .infoUt{
        width:100%;
        order:2;
    }
    .mobBlock table, .mobBlock table tr td{
        border:none;
        text-align:center;
    }
    .legend {
        width:100%;
       
    }
    .pdfLink{
        width:100%;
        order:1;
       
    }
    .pdfLink a{
        padding:10px 20px;
        font-size:1em;
        
        margin-bottom:60px;
        margin-top:60px;
        width:80%;
        margin-left:5%;
    }
}