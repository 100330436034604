body, html {
    background:#fff;
}

body {
    border-top:37px solid #17a096;
}


h1 {
    color:#17a096;
}
.ver{
    font-size:12px; text-align:right; color:lightgray;
    clear:both;
}