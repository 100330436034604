.step1Container {
    width:100%;
    max-width:910px;
    margin:0 auto;
}
.step1 {
    width:100%;
    float:left;
    box-sizing: border-box;
    margin-top:30px;
    background-color:#f8f8f8;
    border:1px solid #e3e3e3;
    font-size:1em;
    padding:40px 70px;
    margin-bottom:80px;

}

.form-group1 {
    float:left;
}

.formSelects {
    float:left;
}

.tempSel {
    height:50px;
    background:#fff;
    text-align:right;
    line-height:50px;
    box-sizing: border-box;
    padding-right:10px;

}
.min {
    color:#848484;
    font-size:0.7em;
}
@media (max-width: 500px) {
    .step1{
        width:90%;
        margin-left:5%;
    }
}
