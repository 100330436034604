.FormClickBlock {

  border: 1px solid #E3E3E3;
  background: url(images/ptaszekE.png) no-repeat;
  background-position: right 10px top 10px;
  background-color: #F8F8F8;
  box-sizing: border-box;
  margin: 3%;
  flex-grow: 1;
  width: 27%;
  height: 250px;
  cursor: pointer;
}

.fullw {
  width: 94%;
  height: 100px;
  cursor: default;

}

.FormClickBlock.clicked {
  background: url(images/ptaszek.png) no-repeat;
  background-position: right 10px top 10px;
  background-color: #F8F8F8;
}

.rowBox {
  display: flex;
  height: 80%;
  flex-wrap: wrap;
  margin-top: 20%;

}

.fullw .rowBox {
  margin-top: 2%;
  height: 80%;
}

.fullw .rowBox .colBox {
  width: 30%;

}

.fullw .rowBox .cbi {
  width: 20%;


}

.fullw select {
  text-align-last: center;
  cursor: pointer;
}

.fullw .rowBox .cbs {
  width: 80%;
  text-align: center;

}

.colBox {
  width: 100%;
  align-self: flex-end;

}

.colBox label,
.colBox p {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1em;
}

.colBox select,
.css-bg1rzq-control {
  width: 90% !important;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 1em;
  border-radius: 0;
  margin-top: 10px;

}

.colBox .css-bg1rzq-control {
  border-radius: 0px !important;
  border: none !important;

}

.css-bgvzuu-indicatorSeparator {
  display: none !important;

}

.css-1pcexqc-container {
  border: 0px solid !important;
  outline: 0px !important
}

.css-1hwfws3 {
  text-align: center;
}

.css-151xaom-placeholder {
  width: 100%;
  TEXT-ALIGN: CENTER;
  color: #000 !important
}

.css-bg1rzq-control,
.css-1szy77t-control {
  border: 0px solid !important;
  border-radius: 0 !important;
  outline: 0px !important;
  box-shadow: none !important;
  width: 90% !important;
  margin-left: 5%
}

.css-16pqwjk-indicatorContainer {
  text-indent: -9999 !important;
  background: url(images/ARROW.png) !important;
}

.colBox img {
  margin: 0 auto;
  display: block;
  max-width: 90%;
}