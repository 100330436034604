.react-tooltip-lite {
    background: #333;
    color: white;
  }
  
  .react-tooltip-lite-arrow {
    border-color: #333;
  }

  .tooltipIco {display:inline-block;
  margin-left:8px;
  }



  label {
    width:60%;
    display:inline-block;
  }

  select {
    height:50px;
    float:right;
  }

  .form-select {
    margin-bottom:20px;
    float:left;
    width:100%;
  }
  .error-info {
    display:none;
    color:red;
    padding:4px;
    font-size:0.8em;
  }

  .error .error-info {
    display:block;
  }

  .error select {
    border:red 1px solid
  }
  
  input[type="number"] {
    height:50px;
    float:right;
    width:100%;
    border:none;
    
    background-color:#fff;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
         appearance: textfield;
         font-size:1em;
         padding-left:5px;
         box-sizing: border-box;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
 -webkit-appearance: none;
}