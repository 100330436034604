button {
    background-color: #17a096;
    border: 1px solid #17a096;
    box-shadow: none;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
    padding: 5px 35px;
    margin-right: 30px;
    cursor: pointer;
}

.buttons {
    text-align: center;
    margin-top: 60px;
    float: left;
    width: 100%;

}

h1 {
    margin-top: 55px;
    text-align: center;
    font-weight: normal;
    font-size: 2em;

}

h1 span {
    border-bottom: 1px solid #707070;
}

h2 {
    margin-top: 40px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2em;
    margin-bottom: 50px
}

.row {
    display: flex;
    align-items: center;
}

.col-1 {
    flex-basis: 65%
}

.col-2 {
    flex-basis: 35%
}

select {
    width: 100%;
    border: none;
    background: url(images/ARROW.png) no-repeat;
    background-position: 97% center;
    background-color: #fff;
    -webkit-appearance: none;
    font-size: 1em;
    padding-right: 30px;
}

.btn-secondary {
    margin-left: 1%;
}

@media (max-width: 500px) {

    .col-1 {
        flex-basis: 100%
    }

    .col-2 {
        flex-basis: 100%;
        margin-bottom: 20px;
    }

    .row {
        flex-direction: row;
        flex-wrap: wrap;
    }

    label {
        margin-bottom: 10px;
        width: 100%;
    }

    .buttons {
        margin-top: 0px;
    }

    .buttons button {
        padding: 10px 20px;
        font-size: 1em;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 90%;
    }

    h1 {
        font-size: 1.75em;

    }

    h2 {
        font-size: 1.5em;
        margin-bottom: 15px;
    }
}